@font-face {
    font-family: 'Octarine';
    src: url('./fonts/Octarine-Bold.woff2') format('woff2'),
        url('./fonts/Octarine-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Octarine';
    src: url('./fonts/Octarine-BoldOblique.woff2') format('woff2'),
        url('./fonts/Octarine-BoldOblique.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Octarine';
    src: url('./fonts/Octarine-Light.woff2') format('woff2'),
        url('./fonts/Octarine-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Octarine';
    src: url('./fonts/Octarine-LightOblique.woff2') format('woff2'),
        url('./fonts/Octarine-LightOblique.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}





